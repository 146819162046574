import React from "react"
import { graphql } from "gatsby"
import { CardDeck } from "react-bootstrap"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Newsletter from "../components/section-newsletter"
import BackgroundInterior from "../components/background/interior"
import CardApp from "../components/card-app"

const Top15Apps = ({ data }) => (
  <Layout>
    <SEO
      title="Top 15 Micro Income Apps"
      description="Explore the top 15 micro income apps! You can earn an extra $50 to $500 a month with one or more of the following micro income apps."
    />
    <BackgroundInterior>
      <Container className="top-15-apps">
        <h1 className="text-center">Explore More Apps!</h1>
        <CardDeck className="card-app-deck my-0">
          {data.allAppsJson.edges.map((app) => {
            const {
              id,
              path,
              title,
              description,
              featured_image,
              alt,
              link_ios,
              link_android,
              link_website,
              link_affiliate_app,
              link_affiliate_web,
              platform,
            } = app.node
            return (
              <CardApp
                key={id}
                title={title}
                description={description}
                image={featured_image.childImageSharp.fluid}
                alt={alt}
                path={path}
                ios={link_ios}
                android={link_android}
                website={link_website}
                affiliate_app={link_affiliate_app}
                affiliate_web={link_affiliate_web}
                platform={platform}
              />
            )
          })}
        </CardDeck>
      </Container>
      <Newsletter />
    </BackgroundInterior>
  </Layout>
)

export const query = graphql`
  query {
    allAppsJson(sort: { order: ASC, fields: order }) {
      edges {
        node {
          id
          path
          title
          description
          featured_image {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          link_ios
          link_android
          link_website
          link_affiliate_app
          link_affiliate_web
          platform
        }
      }
    }
  }
`

export default Top15Apps
